/* Global Styles */
:root {
  --primary-color: #1f48a4;
  --secondary-color: #17a2b8;
  /* --secondary-color: #1890ff; */
  --highlight-color: #1890ff;
  --dark-color: #343a40;
  --light-color: rgb(250, 250, 250);
  --danger-color: #dc3545;
  --success-color: #28a745;
  --background-color: #f0ebeb;
  --gray-color: #757280;
  --light-gray-color: #e4e6e8;
  --bright-green-color: #90ee90;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem;
  line-height: 1.6;
  /* background-color: var(--background-color); */
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

hr {
  color: #e4e6e8;
  border-top: #e4e6e8;
}

/* img {
  width: 100%;
} */
/* Utilities */
.container {
  /* max-width: 1100px; */
  /* margin: auto; */
  /* overflow: hidden;
  padding: 0 2rem; */
  margin-top: 3rem;
  /* margin-bottom: 3rem; */
  min-height: 100vh;
}

.ninety-percent {
  width: 90%;
}

.profile-img-small {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-right {
  display: flex;
  flex-direction: column;
  align-items: right;
}

.flex-spread {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.flex-even {
  display: flex;
  justify-content: space-evenly;
}

.grid {
  display: grid;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.s-large {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
}

.medium {
  font-size: 1.2rem;
}

.small {
  font-size: 0.8rem;
}

.x-small {
  font-size: 0.5rem;
}

.width-25 {
  width: 25%;
}

.width-50 {
  width: 50%;
}

.width-80 {
  width: 80%;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-gray {
  color: var(--gray-color);
}

.text-gold {
  color: #ffd700;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}
.px {
  padding: 0 0.5rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}

.p-auto {
  padding: auto;
}

.p-auto-vert {
  padding: auto 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.mx {
  margin: 0 0.5rem;
}
.mx-1 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.mx-left {
  margin: 0 0 0 0.5rem;
}
.mx-1-left {
  margin: 0 0 0 1rem;
}
.mx-2-left {
  margin: 0 0 0 2rem;
}
.mx-3-left {
  margin: 0 0 0 3rem;
}
.m-auto {
  margin: auto;
}
.m-auto-vert {
  margin: auto 0;
}
/* Icons */
.text-secondary {
  color: var(--secondary-color);
}
.text-gold {
  color: #ffd700;
}
.icon-btn {
  color: var(--secondary-color);
  cursor: pointer;
  height: 100%;
}
.icon-btn:hover {
  opacity: 0.8;
}

.colored {
  background-color: var(--background-color);
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  /* transition: opacity 0.2s ease-in; */
  outline: none;
  border-radius: 0.25rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem;
  text-align: center;
  margin: 0.3rem;
  border-radius: 0.3rem;
  background: var(--light-color);
  color: #333;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.5;
  /* background: var(--light-color); */
  color: #333;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  border-radius: 3rem;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-secondary,
.bg-secondary,
.badge-secondary,
.alert-secondary {
  background: var(--secondary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.alert-important {
  background: var(--success-color);
  color: #fff;
  opacity: 1;
}

.btn-disabled {
  display: inline-block;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border: none;
  /* transition: opacity 0.2s ease-in; */
  outline: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:hover {
  opacity: 0.8;
}

.btn-text {
  background: none;
  color: var(--danger-color);
  padding: 0.1rem 0;
}

.btn-link {
  background: none;
  color: var(--primary-color);
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0.1rem 0;
}

.text-secondary {
  color: var(--secondary-color);
}

.text-primary {
  color: var(--primary-color);
}

.text-gray {
  color: gray;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* .line {
  height: 1px;
  background: #ccc;
  margin: 1.5rem 0;
} */

/* Overlay */
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Forms */
input:focus {
  outline: none;
}
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form input[type='time'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.15rem;
  font-family: 'Roboto', sans-serif !important;
}

.input-text {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.15rem;
  font-family: 'Roboto', sans-serif !important;
}

.form input[type='text']:hover,
.form input[type='email']:hover,
.form input[type='password']:hover,
.form input[type='date']:hover,
.form input[type='time']:hover,
.form select:hover,
.form textarea:hover {
  border-color: var(--highlight-color);
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-around;
  background: #fff;
  align-items: center;
  padding: 0 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 1px #dbdbdb;
  height: 3rem;
}

.navbar ul {
  display: flex;
  margin: auto 0;
}

.navbar a {
  color: #333;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--secondary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.nav-img {
  height: 2rem;
  cursor: pointer;
}

.nav-icon {
  margin: auto;
}

.navbar img {
  height: 100%;
}

.nav-account-img {
  width: 2rem;
  height: 2rem;
}

.nav-flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-wrapper {
  position: relative;
}

.icon-wrapper .icon-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 0.3rem;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8rem;
}

.icon-wrapper .show-top {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 0.3rem;
  position: absolute;
  z-index: 1;
  top: -100%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8rem;
}

.text-explanation {
  width: 15rem;
  margin-left: -7.5rem;
}

.text-home {
  width: 3rem;
  margin-left: -1.5rem;
}

.text-tutors {
  width: 3rem;
  margin-left: -1.5rem;
}

.text-organizations {
  width: 6rem;
  margin-left: -3rem;
}

.text-partners {
  width: 4rem;
  margin-left: -2rem;
}

.text-faq {
  width: 2rem;
  margin-left: -1rem;
}

.text-account {
  width: 4rem;
  margin-left: -2rem;
  margin-top: 0.1rem;
}

.text-sign-out {
  width: 4rem;
  margin-left: -2rem;
}

.icon-wrapper:hover .icon-text {
  visibility: visible;
  opacity: 1;
}

.icon-wrapper:hover .show-top {
  visibility: visible;
  opacity: 1;
}

/* tutors Page */
.tutor-banner {
  height: 10rem;
  width: 100%;
  /*banner image*/
  background: url(img/bannertutor.png) no-repeat 0 -20rem;
  background-size: cover;
}

.tutor-title {
  color: #fff;
  text-align: center;
  padding-top: 30vh;
  font-size: 3rem;
  text-shadow: gray 0 0 2px;
}

.profile {
  background: #fff;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 3fr 2fr;
  /* grid-template-columns: 2fr 9fr 20fr 9fr; */
  /* align-items: center; */
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  /* box-shadow: 0 0 2px #333; */
  border-radius: 2rem;
  border: 1px solid #e4e6e8;
  cursor: pointer;
}

.profile:hover {
  box-shadow: 0 0 20px #e4e6e8;
}

.search {
  display: flex;
  justify-content: center;
  padding-top: 5vh;
}

.search-bar {
  width: 60rem !important;
  max-width: calc(100vw - 19rem);
  height: 3rem;
  font-size: 1.2rem;
  margin: 0 auto;
  border-radius: 0.5rem !important;
}

.search-bar:focus {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.search-suggestions {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 0 2px 12px gray;
  background: #fff;
  padding: 0;
  z-index: 1;
  /* font-size: 90%; */
  scrollbar-width: none;
  -ms-overflow-style: 'none';
  /* max-height: 10rem; */
  overflow: hidden;
  position: relative;
}

.search-suggestions li {
  cursor: pointer;
  padding: 0.2rem 0 0.2rem 1rem;
}

.search-suggestions li:hover {
  background: #fafafa;
}

.search-suggestions::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* .disable-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.disable-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

.search-bar-org {
  width: 100%;
  height: 2rem;
  font-size: 1.2rem;
}

.sticky-form {
  position: sticky;
  position: -webkit-sticky;
  top: 3rem;
  margin-top: 4rem;
}

.profile-list {
  width: calc(100vw - 19rem);
  max-width: calc(100vw - 19rem);
  margin-top: 1rem;
  margin-left: 1rem;
}

.profile img {
  width: 10rem;
  height: 10rem;
  border-radius: 30%;
}

.tutor-text {
  word-wrap: break-word;
  max-width: calc(3 * (100vw - 18rem) / 8);
  color: var(--gray-color);
}

.side-panel {
  /* margin: 1rem; */
  /* border-radius: 0.15rem; */
  padding: 0.5rem;
  /* box-shadow: 0 0 2px #333; */
  width: 15rem;
  height: calc(100vh - 3rem);
  /* position: fixed; */
  position: sticky;
  position: -webkit-sticky;
  top: 3rem;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 1rem;
  /* background: #fafafa; */
  /* z-index: -1; */
  /* border-right: 2px gray solid; */
}
.side-panel::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.highlight {
  color: var(--primary-color);
  cursor: pointer;
}

.pagination {
  display: inline-block;
}

.pagination li {
  color: var(--gray-color);
  float: left;
  width: 2rem;
  height: 2rem;
  /* border: 2px solid black; */
  padding: auto;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.pagination li:hover {
  color: black;
}

.pagination li.active {
  text-decoration: underline;
}

/* Profile Page */
.profile-page {
  display: flex;
  justify-content: center;
}

.profile-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-info {
  width: 50rem;
  /* border-radius: 0.5rem; */
  /* box-shadow: 0 0 2px #333; */
  border-radius: 0.15rem;
  background: #fff;
  margin: 1rem;
  padding: 1rem;
}

.profile-top {
  display: grid;
  grid-template-columns: 5fr 13fr 2fr;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  width: 100%;
}

.profile-top img {
  width: 10rem;
  height: 10rem;
  border-radius: 30%;
}

.profile-mid {
  width: 100%;
  /* border-radius: 0.5rem; */
  padding: 1rem;
}

.tutor-bio {
  width: 48rem;
  word-wrap: break-word;
}

.profile-low {
  width: 50rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem 0 1rem;
}

.request-form {
  width: 50rem;
}

.request-form .btn {
  width: 100%;
  margin-top: 0.5rem;
  border-radius: 0.25rem;
}

.profile-reviews {
  width: calc((100vw - 50rem) * 3 / 4);
  height: 100%;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.15rem;
  background: #fff;
}

/* Account Page */
.account-page {
  display: flex;
  justify-content: center;
}

.account-nav {
  width: calc(100vw * 1 / 3);
  margin: 1rem 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.account-nav img {
  width: 8rem;
  height: 8rem;
  border-radius: 30%;
}
.account-nav-top {
  /* box-shadow: 0 0 2px #333; */
  display: grid;
  border-radius: 0.15rem;
  padding: 1rem;
  background: #fff;
  grid-template-columns: 1fr 2fr;
  align-items: center;
}
.account-nav-mid {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.15rem;
  margin: 1rem 0 0 0;
}
.account-nav-mid div {
  color: #333;
  font-size: 1.1rem;
  padding: 1rem;
}
.account-nav-mid div:hover {
  background-color: var(--light-color);
}
.account-nav-low {
  /* box-shadow: 0 0 2px #333; */
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.15rem;
  background: #fff;
}
.flex-center {
  display: flex;
  align-items: center;
}

.flex-justify-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-column-justify-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.account-body {
  width: calc(100vw * 5 / 9);
  margin: 2rem 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* box-shadow: 0 0 2px #333; */
  background: #fff;
  border-radius: 0.15rem;
}

.school-input {
  width: 28rem;
  max-width: 60%;
}

.bio-input {
  height: 10rem;
}

.subject-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

.new-subject-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}

/* Privacy and terms Page */
.privacy ul {
  list-style-type: disc;
  list-style-position: inside;
}

.privacy ol {
  list-style: decimal;
  list-style-position: inside;
}

.privacy li {
  margin-left: 1rem;
}

footer {
  text-align: center;
  margin-top: 0.5rem;
}

footer .separate {
  display: flex;
  text-align: left;
  justify-content: space-around;
}

/* Landing Page */
/*Showcase*/
.landing {
  background: #fff;
}
.showcase {
  height: 50rem;
  /*banner image*/
  background: url(img/bannerhomepage.png) no-repeat 0 -8rem;
  background-size: cover;
  position: relative;
}

.landing-search {
  display: flex;
  justify-content: center;
  padding-top: 12rem;
}

.landing-search-bar:focus {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.landing-search-bar {
  width: calc(100vw * 2 / 5) !important;
  height: 4rem;
  font-size: 1.5rem !important;
  /* margin: 0 auto; */
  border-radius: 0.5rem !important;
}

.has-search .form-control {
  padding-left: 3rem !important;
}

.has-search .form-control-2 {
  padding-left: 2.5rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  /* z-index: 2; */
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.has-search .form-control-feedback-2 {
  position: absolute;
  /* z-index: 2; */
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  margin-left: 0.5rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.explore {
  /* border-radius: 0.25rem; */
  /* border: 2px solid black; */
  /* background-color: #fff; */
  /* box-shadow: 0 0 2px gray; */
  width: 20rem;
  color: #fff;
  left: 4%;
  position: absolute;
  bottom: 20rem;
  text-shadow: 0 0 1px gray;
}

.explore h1 {
  font-size: 2rem;
}

.sign-in {
  width: 15rem;
  padding: 1rem 2rem;
  color: var(--danger-color);
  border-radius: 0.25rem;
  box-shadow: 0 0 2px gray;
  position: absolute;
  bottom: 20rem;
  right: 4%;
  background-color: #fff;
}

.sign-in img {
  cursor: pointer;
  width: 100%;
}

/*second page*/
.page-two {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 4rem;
  justify-content: space-evenly;
}
.column {
  width: 30rem;
  padding-right: 5rem;
}

.column h1 {
  font-size: 3.15rem;
  text-align: center;
}

.column p {
  /* padding-left: 5rem; */
  font-size: 1.4rem;
}

.page-two img {
  height: 24rem;
  position: relative;
  margin-left: 3rem;
}

.picture img {
  width: 100%;
  height: 100%;
}

/*third page*/
/* .student-promotion {
  background-color: snow;
} */
.page-three {
  /* margin-top: 2rem; */
  /* background: #fff; */
  color: #141c3a;
  height: 35rem;
}

.page-three h1 {
  text-align: center;
  font-size: 2.5rem;
  padding: 2rem 0;
}

.page-three h2 {
  font-size: 1.6rem;
  border-bottom: 1px solid lightgray;
}

.page-three p {
  font-size: 1.125rem !important;
}

.three-col {
  display: flex;
  margin-top: 3rem;
  justify-content: center;
}

.three-col img {
  height: 4.8rem;
  width: 4.8rem;
}

.col {
  padding: 0 1rem;
  text-align: center;
  margin: 0 3rem;
  width: 20rem;
}

/*Fourth page */
.page-four {
  background: url(img/bannertutor.png) no-repeat 0 -15rem;
  background-size: cover;
  color: #fff;
  height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 1px gray;
}
.two-col {
  display: flex;
  justify-content: center;
  padding: 10rem 1rem;
}
.sec {
  width: 40rem;
}
.sec img {
  width: 90%;
}
.sec h1 {
  margin-top: 1rem;
  font-size: 2.9rem;
}
.sec p {
  margin-top: 1rem;
  font-size: 1.4rem;
}

/* Students */

.page-student {
  margin: 2rem 0;
  /* background: #fff; */
  background-color: #f9fff8;
  color: #141c3a;
  height: auto;
}

.page-student h1 {
  text-align: center;
  font-size: 2.5rem;
  padding: 2rem 0;
}

.page-student h2 {
  font-size: 1.6rem;
  border-bottom: 1px solid lightgray;
}

.page-student p {
  font-size: 1.125rem !important;
}

.three-col-2 {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.three-col-2 img {
  height: 4.8rem;
  width: 4.8rem;
}

.col-2 {
  padding: 0 1rem;
  text-align: center;
  margin: 0 1rem;
  width: 25rem;
}

.popular-subjects {
  padding: 0 10rem;
  background-color: mintcream;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
}

.boxes li {
  width: 10rem;
  height: 2rem;
  border-radius: 0.5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*fifth page*/
/* .tutor-promotion {
  background-color: #f1f3fe;
} */
.page-five {
  color: #141c3a;
  /* background-color: #f9fff8; */
}

.page-five h1 {
  font-size: 2.5rem;
}

.three-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  flex: 1;
  width: 50rem;
  margin-bottom: 2rem;
}

.row img {
  height: 10rem;
  width: 10rem;
}
.horizontal {
  margin-left: 7rem;
}

.horizontal h2 {
  font-size: 1.6rem;
}

/*Sixth Page */
.page-six {
  background-color: mintcream;
}
.page-six h1 {
  text-align: center;
  font-size: 2.5rem;
  padding: 3rem;
}
.three-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10rem;
}
.line {
  display: flex;
  flex: 1;
  width: 50rem;
  margin-bottom: 3rem;
}

.line h2 {
  font-size: 1.7rem;
}
.text-box {
  margin: 0 2rem;
}
.fa-large {
  width: 5rem !important;
  height: 5rem !important;
  color: var(--primary-color);
}
.fa-medium {
  width: 2rem !important;
  height: 2rem !important;
}

/*get started now section*/
.join {
  text-align: center;
  /* margin-bottom: 3rem; */
  padding-bottom: 5rem;
  display: flex;
  /* background-color: mintcream; */
  flex-direction: column;
  align-items: center;
}
.join h1 {
  margin: 2rem 0;
  font-size: 2.7rem;
}

.join .btn {
  width: 20rem;
  height: 3rem;
  border-radius: 0.5rem;
}

.press-heading {
  margin: 1rem 0 1rem 0;
  font-size: 2.5rem;
  text-align: center;
}

.press-banner {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Dashboard Page */
.dashboard-1 {
  height: 10rem;
  width: 100%;
  display: flex;
  /* margin: -1rem -1rem 0 -1rem; */
}

.dashboard-2 {
  width: 100%;
  /* margin: 0 -1rem 0 -1rem; */
  /* margin-left: -1rem; */
  min-height: 5rem;
  display: flex;
}

.dashboard-3 {
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px var(--light-gray-color);
}

.streak-bar {
  /* height: 10rem; */
  /* background-color: var(--light-gray-color); */
  /* border-radius: 0.5rem; */
  /* box-shadow: 0 0 10px var(--light-gray-color); */
  /* margin: -1rem; */
  border: 1px var(--light-gray-color) solid;
  width: 70%;
}

.tier-display {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px var(--light-gray-color) solid;
  border-left: none;
}

.streak-bar li {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.streak-bar .fill {
  background-color: var(--bright-green-color);
}

.streak-bar .unfill {
  background-color: var(--light-gray-color);
}

.tier-img {
  height: 12rem;
  width: 12rem;
}

.tier-img-unranked {
  width: 9rem;
  height: 9rem;
}

.next-meeting {
  width: 50%;
  border: 1px solid var(--light-gray-color);
  border-top: none;
}

.recent {
  width: 50%;
  border-bottom: 1px solid var(--light-gray-color);
  border-right: 1px solid var(--light-gray-color);
}

.boxed {
  padding: 0 1rem 0.5rem 1rem;
  border-radius: 0.15rem;
  box-shadow: 0 0 1px var(--dark-color);
  width: 32%;
  text-align: center;
}

.dashboard-3 img {
  width: 12rem;
  height: 12rem;
}

.tier-list {
  display: flex;
  justify-content: space-between;
}

.tier-description {
  text-align: left;
}

.badge-list {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.badge-description {
  text-align: left;
}

.badge-img {
  width: 8rem;
  height: 8rem;
}

.badge-boxed {
  width: 25%;
  padding: 1rem 1rem 0.5rem 1rem;
  /* border-radius: 0.15rem; */
  /* box-shadow: 0 0 1px var(--dark-color); */
  text-align: center;
}

.unqualified {
  color: var(--light-gray-color);
  opacity: 0.5;
  filter: grayscale(100%);
}

.show-more {
  /* margin: 1rem; */
  padding-bottom: 1rem;
}

.show-more:hover {
  text-decoration: underline;
}

.claim-btn {
  border-radius: 1rem;
}

/* Meeting Page */
.student-item {
  min-height: 3rem;
}

.student-item img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.student-item p {
  margin: auto 0;
  font-size: 1.2rem;
}

.student-item .flex {
  width: 50%;
}

.right {
  justify-content: flex-end;
}

/* Meetings */
.meeting-item {
  min-height: 3rem;
}

.meeting-item img {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  margin: auto 0;
}

.meeting-item p {
  margin: auto 0;
  font-size: 1.2rem;
}

.personal-upcoming-meeting {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
}

.personal-upcoming-meeting .meeting-col {
  min-height: 3rem;
  width: 100%;
}

.personal-past-meeting {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
}

.personal-past-meeting .meeting-col {
  min-height: 3rem;
  width: 100%;
}

/* FAQ Page */
.faq h1 {
  margin-top: 3%;
  text-align: center;
  border-bottom: 1px solid #dbdbdb;
}
.faq h2 {
  font-size: 1.3rem;
}
.faq p {
  font-size: 1.1rem;
}
.q {
  font-weight: bold;
}
.faq ol {
  list-style-position: inside;
}
.faq li {
  margin-left: 1rem;
}

/* Support Page */
.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.15rem;
  background-color: #fff;
  margin-top: 3rem;
  padding: 2rem;
}

.contact-card ol {
  list-style-position: inside;
}

.contact-card ol li {
  margin-left: 1rem;
}

/* hr.break {
  border-top: 5px solid navy;
  width: 100%;
  border-radius: 10%;
} */

/* Orgs List */
.orgs-page {
  display: flex;
  justify-content: center;
}

.org-inner {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  width: 50rem;
}

.org-item {
  background: #fff;
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 7fr 14fr;
  /* align-items: center; */
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
  /* box-shadow: 0 0 2px #333; */
  border-radius: 0.15rem;
  border: 1px solid #e4e6e8;
  cursor: pointer;
}

.org-item:hover {
  box-shadow: 0 0 20px #e4e6e8;
}

.org-item img {
  width: 100%;
  border-radius: 30%;
}

/* Org Form */

.org-form {
  background-color: #fff;
  padding: 1rem;
  width: 40rem;
  margin: 1rem 0;
  border-radius: 0.15rem;
}

.org-form textarea {
  height: 10rem;
}

.org-form .btn {
  width: 100%;
  border-radius: 0.25rem;
}

/*Org Page */

/*View 2*/
.org-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #fff;
  border-radius: 0.15rem;
  margin: 1rem 0;
  width: 50rem;
  padding: 1rem;
  /* box-shadow: 0 0 2px #333; */
}

.org-top {
  display: grid;
  grid-template-columns: 3fr 6fr 1fr;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  width: 100%;
}

.org-top img {
  width: 100%;
  border-radius: 30%;
}

.org-bottom {
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
}

.org-width {
  width: 70rem;
}

.org-header {
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-gap: 2rem;
  padding: 1rem;
  line-height: 1.8;
  width: 100%;
  background: #fff;
  border-radius: 0.15rem;
  /* box-shadow: 0 0 2px #333; */
  margin: 1rem 0;
}

.org-header img {
  width: 100%;
  max-height: 14rem;
  border-radius: 30%;
}

.float-right {
  float: right;
}

.org-body {
  padding: 1rem;
  background: #fff;
  width: 100%;
  /* box-shadow: 0 0 2px #333; */
  border-radius: 0.15rem;
  margin: 1rem 0;
}

.org-form-edit {
  background-color: #fff;
  /* padding: 1rem; */
  width: 100%;
  /* margin: 1rem 0; */
  border-radius: 0.15rem;
}

.org-form-edit textarea {
  height: 10rem;
}

.org-form-edit .btn {
  width: 100%;
  border-radius: 0.25rem;
}

.participant-item {
  height: 2rem;
  /* width: 10rem; */
  margin-left: 1rem;
}

.participant-item img {
  height: 100%;
  width: auto;
  border-radius: 50%;
}

.participant-item .flex-spread {
  width: 20rem;
}

.org-past-meeting {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
}

.org-upcoming-meeting {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
}

.announcement-item {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 1rem;
}

.announcement-msg {
  word-wrap: break-word;
  width: 57rem;
}

.announcement-item img {
  height: 5rem;
}

.announcement-container {
  max-height: 30rem;
  /* height: auto; */
  overflow-y: scroll;
}

.height-3 {
  height: 3rem;
  width: 100%;
}

.height-3 img {
  height: 100%;
  width: 3rem;
  border-radius: 50%;
}

.height-3 p {
  margin: auto 0;
}

.height-3 .flex-spread {
  width: 100%;
}

.hide-lg {
  display: none;
}

/* Chat */
.chat-container {
  display: flex;
  justify-content: center;
}

.chat-side-bar {
  width: 30rem;
  height: 40rem;
  background: #fff;
  overflow-y: scroll;
  margin: 1rem 0.5rem;
}

.chat-side-bar li {
  cursor: pointer;
  padding: 1rem;
}

.chat-side-bar li:hover {
  background-color: #e4e6e8;
}

.chat-item {
  display: flex;
  height: 3rem;
}

.chat-item img {
  height: 100%;
  width: auto;
  border-radius: 50%;
}

.chat-box {
  width: 50rem;
  height: 38rem;
  background: #fff;
  overflow-y: scroll;
  margin: 1rem 0.5rem 0 0.5rem;
}

.chat-message {
  display: grid;
  height: auto;
  grid-template-columns: 1fr 4fr;
  padding: 1rem;
}

.chat-message img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}

.chat-text {
  word-wrap: break-word;
  width: 40rem;
}

.chat-box-bottom {
  margin: 0;
  width: 50rem;
  margin: 0 0.5rem;
}

.chat-msg {
  height: 2rem;
  font-size: 1rem;
}

.zoom-button {
  margin: 1rem 0;
}

.zoom-button img {
  height: 2rem;
}

.zoom-button a {
  display: flex;
  align-items: center;
}

.zoom-button p {
  margin: auto 0.5rem;
}

/* Partners page */
.partners-head {
  height: 10rem;
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;
}

.partners-body {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-item {
  width: 50rem;
  display: flex;
}

.partner-item img {
  width: 10rem;
  height: 10rem;
  border-radius: 0.25rem;
}

.hide-big {
  display: none;
}

/* about page */
.about-banner {
  height: 70vh;
  width: 100%;
  /*banner image*/
  /* background: url(img/group-photo.JPG) no-repeat 0 -100px; */
  /* background: url(img/group-photo.JPG) no-repeat 0 -100px,
    linear-gradient(#eb01a5, #d13531); */
  background-image: linear-gradient(
      to top,
      rgba(20, 20, 20, 1),
      rgba(0, 0, 0, 0)
    ),
    url(img/group-photo.JPG);
  background-size: cover;

  /* Add the blur effect */
  /* filter: blur(1px);
  -webkit-filter: blur(1px); */
}

.about-banner-text {
  text-align: center;
  color: var(--background-color);
  position: absolute;
  text-shadow: 0 0 1px gray;
  left: 20%;
  right: 20%;
  bottom: 15rem;
}

.team-member-img {
  border-radius: 50%;
  height: 10rem;
  width: 10rem;
}

.about-team-members {
  display: flex;
  justify-content: space-evenly;
}

.about-team-member {
  width: 18rem;
}

.team-member-description {
  width: 100%;
}

/* big styles */
@media (min-width: 1848px) {
  .profile {
    grid-template-columns: 1fr 2fr 4fr 3fr;
  }
  .tutor-text {
    max-width: calc(2 * (100vw - 18rem) / 5);
  }
}

@media (min-height: 1599px) {
  .showcase {
    background: url(img/bannerhomepage.png) no-repeat 0 -7rem;
  }
  .landing-search {
    padding-top: 13rem;
  }
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-big {
    display: block;
  }

  .container {
    margin-top: 4.5rem;
  }

  .hide-sm {
    display: none;
  }

  .hide-lg {
    display: inline-block;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    display: flex;
    text-align: center;
    flex-direction: column;
    height: 5rem;
    padding: 0 0.5rem;
    width: 100%;
  }

  .navbar ul {
    text-align: center;
    justify-content: center;
  }

  .navbar h1 {
    margin-bottom: 0.5rem;
  }

  .navbar .welcome {
    display: none;
  }

  .nav-img {
    height: 1.5rem;
  }

  .nav-account-img {
    width: 1.5rem;
    height: 1.5rem;
  }

  /*footer*/
  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  footer .separate {
    flex-direction: column;
  }
  /*Landing Page*/
  /*Showcase*/
  .showcase {
    /* min-height: 525px;
    margin-top: -3rem; */
    /*banner image*/
    background-size: cover;
    background: url(img/bannerhomepage.png) -70rem -10rem;
    /* color: #fff; */
    display: flex;
    /* padding: 1rem; */
    height: 10rem;
    /* flex-direction: column;
    justify-content: center; */
  }

  .landing-search form {
    width: 80%;
  }

  .landing-search {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding-top: 2rem;
    width: 100%;
  }

  .landing-search-bar {
    width: 100% !important;
    margin: auto;
  }

  .explore {
    display: none;
  }

  .sign-in {
    display: none;
    /* padding-top: 2rem; */
  }

  /*second page*/
  .page-two {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
  }
  .column {
    width: 100%;
    padding-right: 0;
  }

  .column h1 {
    font-size: 3.15rem;
    text-align: center;
  }

  .column p {
    padding: 1rem;
    text-align: center;
    font-size: 1.4rem;
  }

  .page-two img {
    width: 100%;
    height: 20rem;
    position: relative;
    margin-left: 0;
    margin-top: 2rem;
  }

  /*third page*/
  /* .student-promotion {
    background-color: snow;
  } */
  .page-three {
    margin-top: 0;
    background: #fff;
    color: #141c3a;
    height: auto;
    padding: 0;
  }

  .page-three h1 {
    text-align: center;
    font-size: 2rem;
    padding: 2rem 0;
  }

  .page-three h2 {
    font-size: 1.6rem;
    border-bottom: 1px solid lightgray;
  }

  .page-three p {
    font-size: 1rem;
  }

  .three-col {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .three-col img {
    height: 4.8rem;
    width: 4.8rem;
  }

  .col {
    padding: 0 1rem;
    text-align: center;
    margin: 1rem 3rem;
    width: auto;
  }

  /*Fourth page */
  .page-four {
    /* background-color: mintcream; */
    /* color: #333; */
    background: url(img/bannertutor.png) no-repeat 0 -15rem;
    padding: 0 2rem;
  }
  .two-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 1rem 0;
  }
  .sec {
    width: auto;
  }
  .sec img {
    width: 100%;
  }
  .sec h1 {
    margin-top: 1rem;
    font-size: 2rem;
    padding: 0 1rem;
    text-align: center;
  }
  .sec p {
    margin-top: 1rem;
    font-size: 1.4rem;
    text-align: center;
    padding: 0 1rem;
  }

  /* Students */

  .page-student {
    margin: 2rem 0;
    /* background: #fff; */
    color: #141c3a;
    height: auto;
  }

  .page-student h1 {
    text-align: center;
    font-size: 2.5rem;
    padding: 2rem 0;
  }

  .page-student h2 {
    font-size: 1.6rem;
    border-bottom: 1px solid lightgray;
  }

  .page-student p {
    font-size: 1.125rem !important;
  }

  .three-col-2 {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .three-col-2 img {
    height: 4.8rem;
    width: 4.8rem;
  }

  .col-2 {
    padding: 0 1rem;
    text-align: center;
    margin: 1rem;
    width: auto;
  }

  /*fifth page*/
  /* .tutor-promotion {
    background-color: #f1f3fe;
  } */
  .page-five {
    margin-bottom: 1rem;
    color: #141c3a;
  }

  .three-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
  }

  .row {
    display: flex;
    flex: 1;
    width: auto;
    margin-bottom: 2rem;
  }

  .row img {
    margin-top: 0.5rem;
    height: 5rem;
    width: 5rem;
  }
  .horizontal {
    margin-left: 1rem;
  }
  .horizontal h2 {
    font-size: 1.5rem;
  }

  /*Sixth Page */
  /* .page-six {
    background-color: ghostwhite;
  } */
  .page-six h1 {
    text-align: center;
    font-size: 2rem;
    padding: 1rem;
  }
  .three-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    padding: 0 1rem;
  }
  .line {
    display: flex;
    flex: 1;
    width: auto;
    margin-bottom: 3rem;
  }

  .line h2 {
    font-size: 1.2rem;
  }
  .text-box {
    margin: 0 2rem;
  }
  .fa-large {
    width: 5rem !important;
    height: 5rem !important;
  }

  /*get started now section*/
  .join {
    text-align: center;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join h1 {
    margin: 2rem 0;
    font-size: 2.7rem;
  }

  .join .btn {
    width: 20rem;
    height: 3rem;
    border-radius: 0.5rem;
  }

  /* tutors Page */
  .tutors-page {
    display: flex;
    flex-direction: column;
  }
  .tutor-banner {
    /* height: 70vh; */
    width: 100%;
    /*banner image*/
    background: url(img/bannertutor.png) no-repeat 0;
    background-size: cover;
  }
  .tutor-title {
    color: #fff;
    text-align: center;
    padding-top: 40vh;
    font-size: 1.2rem;
    text-shadow: gray 0 0 2px;
    display: none;
  }
  .profile {
    background: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 0 0 2px #333; */
    border-radius: 0.15rem;
  }
  .mobile-center {
    text-align: center;
  }
  .mobile-center .lead {
    font-size: 2rem;
  }
  .search {
    width: 100%;
  }
  .search form {
    width: 80%;
  }
  .search-bar {
    width: 100% !important;
    height: 2rem;
    font-size: 1.2rem;
    margin: auto;
    max-width: 100%;
  }
  .has-search .form-control-feedback-2 {
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    margin-top: 0.25rem;
    margin-left: 0.5rem;
  }
  .search-bar-org {
    width: 100%;
    max-width: 100%;
    height: 2rem;
    font-size: 1.2rem;
  }

  .profile-list {
    margin: 1rem;
    width: auto;
    max-width: 100%;
  }

  .side-panel {
    margin: 1rem;
    border-radius: 0.15rem;
    padding: 0.5rem;
    /* box-shadow: 0 0 2px #333; */
    height: 50vh;
    width: auto;
    position: static;
    background: #fff;
  }

  .highlight {
    color: var(--primary-color);
    cursor: pointer;
  }

  /* Profile Page */
  .profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-info {
    width: calc(100vw - 2rem);
    /* border-radius: 0.5rem; */
    /* box-shadow: 0 0 2px #333; */
    border-radius: 0.15rem;
    background: #fff;
    margin: 1rem;
  }

  .profile-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .profile-top img {
    width: 10rem;
    height: 10rem;
    border-radius: 30%;
    margin-bottom: 1rem;
  }

  .profile-mid {
    width: auto;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .tutor-bio {
    width: auto;
    max-width: 24rem;
  }

  .profile-low {
    width: auto;
    display: block;
    justify-content: none;
    flex-wrap: none;
    padding: 1rem;
  }

  .request-form {
    width: calc(100vw - 2rem);
  }

  .profile-reviews {
    width: calc(100vw - 2rem);
    height: 100%;
    margin: 1rem;
    padding: 1rem;
    border-radius: 0.15rem;
    background: #fff;
  }

  /* Account Page */
  .account-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .account-nav {
    width: auto;
    margin: 1rem 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .account-nav-top {
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 0 2px #333; */
    border-radius: 0.15rem;
    padding: 1rem;
    background: #fff;
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .account-nav-low {
    /* box-shadow: 0 0 2px #333; */
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 0.15rem;
    background: #fff;
  }

  .account-body {
    width: auto;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0 0 2px #333; */
    background: #fff;
    border-radius: 0.15rem;
  }

  .school-input {
    width: 28rem;
  }

  .bio-input {
    height: 10rem;
  }

  .subject-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
  }

  .new-subject-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
  }

  /* Dashboard Page */
  .dashboard-2 {
    width: 100%;
    /* margin: 0 -1rem 0 -1rem; */
    /* margin-left: -1rem; */
    min-height: 5rem;
    display: flex;
    flex-direction: column;
  }

  .streak-bar {
    width: 60%;
  }

  .tier-display {
    width: 40%;
  }

  .boxed {
    width: auto;
  }

  .next-meeting {
    width: 100%;
    /* border: 1px solid #e4e6e8;
    border-top: none; */
  }

  .recent {
    width: 100%;
    border: 1px solid #e4e6e8;
    border-top: none;
  }

  .tier-list {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }
  .tier-description {
    text-align: center;
  }
  .badge-list {
    flex-direction: column;
  }
  .badge-boxed {
    width: auto;
  }
  /* Meeting Page */
  .student-item {
    min-height: 3rem;
  }

  .student-item p {
    margin: auto 0;
    font-size: 1.2rem;
  }

  .student-item .flex {
    width: 50%;
  }

  .right {
    justify-content: flex-end;
  }

  /* Meetings */
  /* .meeting-item {
    height: 3rem;
  } */

  /* .meeting-item img {
    height: 100%;
    width: auto;
    border-radius: 50%;
  } */

  /* .meeting-item p {
    margin: auto 0;
    font-size: 1.2rem;
  } */

  /* Orgs List */
  .orgs-page {
    display: flex;
    justify-content: center;
  }

  .org-inner {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 50rem;
  }

  .org-item {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* box-shadow: 0 0 2px #333; */
    border-radius: 0.15rem;
  }

  .org-item img {
    width: 25%;
    border-radius: 30%;
  }

  /* Org Form */

  .org-form {
    background-color: #fff;
    padding: 1rem;
    width: 100%;
    margin: 1rem;
    border-radius: 0.15rem;
  }

  .org-form textarea {
    height: 10rem;
  }

  .org-form .btn {
    width: 100%;
    border-radius: 0.25rem;
  }

  /*Org Page */

  /*View 2*/
  .org-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #fff;
    border-radius: 0.15rem;
    margin: 1rem;
    width: auto;
    padding: 1rem;
    /* box-shadow: 0 0 2px #333; */
  }

  .org-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .org-top img {
    width: 25%;
    border-radius: 30%;
  }

  .org-bottom {
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  .org-width {
    width: 100%;
  }

  .org-header {
    display: flex;
    flex-direction: column;
    width: auto;
    background: #fff;
    border-radius: 0.15rem;
    /* box-shadow: 0 0 2px #333; */
    margin: 1rem;
    align-items: center;
  }

  .org-header img {
    width: 25%;
    max-height: 8rem;
    border-radius: 30%;
  }

  .float-right {
    float: right;
  }

  .org-body {
    padding: 1rem;
    background: #fff;
    width: auto;
    /* box-shadow: 0 0 2px #333; */
    border-radius: 0.15rem;
    margin: 1rem;
  }

  .org-form-edit {
    background-color: #fff;
    /* padding: 1rem; */
    width: 100%;
    /* margin: 1rem 0; */
    border-radius: 0.15rem;
  }

  .org-form-edit textarea {
    height: 10rem;
  }

  .org-form-edit .btn {
    width: 100%;
    border-radius: 0.25rem;
  }

  .participant-item {
    height: 2rem;
    margin-left: 1rem;
  }

  .participant-item img {
    height: 100%;
    width: auto;
    border-radius: 50%;
  }

  .mobile-form {
    display: flex;
    flex-direction: column;
  }

  .mobile-form div {
    margin: 0 2rem;
  }

  .org-nav {
    flex-direction: column;
  }
  .org-nav li {
    margin: 0 1rem;
  }
  .participant-item .flex-spread {
    width: auto;
  }

  .announcement-msg {
    word-wrap: break-word;
    width: 17rem;
  }

  /* Chat */
  .chat-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .chat-side-bar {
    width: auto;
    height: 30vh;
    background: #fff;
    overflow-y: scroll;
    margin: 1rem 0.5rem;
  }

  .chat-side-bar li {
    cursor: pointer;
    padding: 1rem;
  }

  .chat-side-bar li:hover {
    background-color: #e4e6e8;
  }

  .chat-item {
    display: flex;
    height: 3rem;
  }

  .chat-item img {
    height: 100%;
    width: auto;
    border-radius: 50%;
  }

  .chat-box {
    width: auto;
    height: 35vh;
    background: #fff;
    overflow-y: scroll;
    margin: 1rem 0.5rem 0 0.5rem;
  }

  .chat-message {
    display: grid;
    height: auto;
    grid-template-columns: 1fr 4fr;
    padding: 1rem;
  }
  .chat-text {
    word-wrap: break-word;
    width: 20rem;
  }

  .chat-message img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
  }

  .chat-box-bottom {
    margin: 0;
    width: auto;
    margin: 0 0.5rem;
  }

  .chat-msg {
    height: 2rem;
    font-size: 1rem;
  }
  /* Partners page */
  .partners-head {
    height: 10rem;
    display: flex;
    background: #fff;
    flex-direction: column;
    justify-content: center;
  }

  .partners-body {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partner-item {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .partner-item img {
    width: 10rem;
    height: 10rem;
    border-radius: 0.25rem;
  }

  /*About Page*/
  .about-banner {
    height: 30vh;
  }
  .about-banner-text {
    left: 0%;
    right: 0%;
    bottom: 30rem;
  }
  .about-team-members {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-team-member {
    margin-bottom: 1rem;
  }
}
